'use strict';

angular.module('windmanagerApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('shared', {
        url: '/shared/:id',
        template: '<shared></shared>',
        // authenticate: true,
        data: {
          bodyClass: 'shared'
        }
      });
  });
